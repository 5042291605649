{
    "error_screen__renderer_failed": "Your graphics card may not support UpTogether! Please try on again on a WebGL2 enabled browser & device.",
    "discord_reward": "¡Únete a nuestro Discord para desbloquear una skin especial!",
    "error_screen__server_maybe_full": "Error al conectar al servidor: los servidores pueden estar llenos en este momento, ¡inténtalo de nuevo en unos minutos!",
    "error_screen__maintenance_mode": "¡UpTogether.io se está actualizando! Vuelve en unos minutos para probar las novedades 🎉",
    "error_screen__client_out_of_date": "¡Tu cliente de juego está desactualizado! Actualiza tu cliente de juego a la última versión e intenta de nuevo.",
    "error_screen__invalid_connection": "¡El servidor rechazó tu usuario! Por favor, inténtalo de nuevo.",
    "error_screen__already_logged_in": "¡Ya has iniciado sesión!",
    "error_screen__unknown_reason": "Error de conexión desconocido :(",
    "error_screen__disconnected_from_server": "Desconectado del servidor. Los servidores pueden estar cerrándose para una actualización. Intenta de nuevo en unos minutos.",
    "heightmarker__to_bottom": "¡De vuelta abajo!",
    "heightmarker__inspiration_come_far": "¡Mira cuán lejos has llegado!",
    "heightmarker__inspiration_keep_going": "¡Sigue adelante!",
    "heightmarker__inspiration_up_up": "¡Arriba, arriba, arriba!",
    "in_game__secret_edward": "¡Hola Edward!",
    "heightmarker__secret_gorillas": "¿Cuántos gorilas puedes encontrar?",
    "heightmarker__change_character": "Cambiar personaje",
    "heightmarker__change_name": "Cambiar nombre",
    "heightmarker__change_trail": "¡Consigue una estela!",
    "heightmarker__global_leaderboard": "Clasificación global",
    "heightmarker__your_stats": "TUS ESTADÍSTICAS",
    "heightmarker__game_stats": "ESTADÍSTICAS DEL JUEGO",
    "heightmarker__tutorial_speed": "Velocidad",
    "heightmarker_tutorial_boost": "¡Impulso!",
    "heightmarker__tutorial_climb": "ESCALA",
    "heightmarker__tutorial_the_wall": "LA PARED",
    "heightmarker__tutorial_bouncy": "¡Saltarín!",
    "in_game__secret_invis_maze": "Junto a la puerta del laberinto, a la izquierda...",
    "in_game__secret_upside_down_plate": "Salta hacia adelante desde el plato boca abajo.",
    "in_game__tutorial_start_timer": "Pasa la línea de inicio para iniciar tu temporizador",
    "in_game__tutorial_invis_platforms": "¡Hay plataformas invisibles!",
    "in_game__how_fast_top": "¿Qué tan rápido puedes llegar a la cima?",
    "in_game__no_touchy": "¡NO TOCAR!",
    "in_game__tutorial_running": "Usa la palanca izquierda para correr",
    "in_game__tutorial_tap": "Toque",
    "in_game__tutorial_press": "Presiona",
    "in_game__tutorial_or": "o",
    "in_game__tutorial__to_jump": "Para saltar",
    "in_game__tutorial__hold": "Mantén",
    "in_game__tutorial__jump_repeatedly": "Para saltar repetidamente",
    "in_game__tutorial_wall_climb": "Para escalar paredes",
    "in_game__tutorial_while_running": "Mientras corres",
    "in_game__tutorial_drag_move_camera": "Arrastra la pantalla para mover la cámara",
    "in_game__checkpoint": "¡Control!",
    "loading_text__connecting": "Conectando al servidor...",
    "loading_text__splines": "Reticulando splines",
    "loading_text__tofu": "Marinando el tofu",
    "loading_text__corn": "Desgranando el maíz",
    "loading_text__broccoli": "Confirmar que el brócoli es arbolitos pequeños",
    "loading_text__brussel_sprouts": "Convenciendo a los jugadores de que las coles de Bruselas son deliciosas",
    "loading_text__secret_sauce": "Añadiendo la salsa secreta",
    "loading_text__digesting": "Digestión en curso",
    "loading_text__stirring": "Revuelto constante",
    "loading_text__fixing_ice_cream": "Arreglando la máquina de helados en McDonald's",
    "loading_text__chopping_broccoli": "Picando el brócoli",
    "loading_text__carmelize_apples": "Caramelizando las manzanas",
    "loading_text__water_boil": "Observando el agua hervir",
    "loading_text__filleting_fish": "Fileteando el pescado",
    "loading_text__plating": "Presentación",
    "loading_text__garnishing": "Guarnición",
    "loading_text__moldy_bits": "Cortando las partes mohosas",
    "death_block__almost": "¡Casi!",
    "death_block__close_one": "¡Por poco!",
    "death_block__try_again": "¡Inténtalo de nuevo!",
    "death_block__roasted": "¡Estás asado!",
    "death_block__burnt": "¡Estás quemado!",
    "death_block__oh_no": "¡Oh no!",
    "death_block__grilled": "¡Estás a la parrilla!",
    "death_block__blanched": "¡Estás escaldado!",
    "death_block__crisp": "¡Estás crujiente!",
    "death_block__flameed": "¡Estás flameado!",
    "death_block__order_up": "¡Orden lista!",
    "death_block__ouch": "¡Ay!",
    "death_block__good_try": "¡Buen intento!",
    "death_block__baked": "¡Estás horneado!",
    "death_block__crusty": "¡Estás crujiente!",
    "death_block__next_time": "¡La próxima vez!",
    "death_block__toast": "¡Estás tostado!",
    "death_block__no_chef": "¡No hay chef!",
    "death_block__singe_left": "¡Solo un toque a la izquierda!",
    "death_block__oof": "¡Ay!",
    "death_block__fried": "¡Tropiezo y frito!",
    "death_block__recipe_ruined": "Receta arruinada",
    "death_block__sizzling_stop": "¡Una parada chisporroteante!",
    "death_block__defeated": "¡Chef derrotado!",
    "death_block__burnt_out": "¡Quemado!",
    "death_block__meltdown": "¿Tienes un colapso?",
    "falling_death__slippery": "¡Resbaladizo!",
    "falling_death__gg": "GG",
    "falling_death__you_died": "Has muerto ",
    "falling_death__f": "F",
    "falling_death__floor_food": "¡Comida en el suelo!",
    "falling_death__three_second": "¡Regla de los tres segundos!",
    "falling_death__floor_seasoning": "¡Mmm! ¡Sazonado con el suelo!",
    "falling_death__just_missed": "¡Apenas fallado!",
    "falling_death__rip": "Descansa en paz",
    "falling_death__floor_vs_flavor": "Suelo: 1",
    "falling_death__floor_fall": "¡Fallo en el suelo!",
    "falling_death__saucy_slip": "¡Resbalón sabroso!",
    "falling_death__fried_fell": "¡Frito y caído!",
    "falling_death__dive": "¡Zambullida insípida!",
    "falling_death__floor_finish": "¡Terminado en el suelo!",
    "falling_death__dinner_dive": "¡Zambullida de la cena!",
    "falling_death__splat": "¡SPLAT!",
    "falling_death__oops": "Oops",
    "falling_death__greasy_spot": "¡Encontraste el lugar grasiento!",
    "falling_death__barely_missed": "¡Apenas fallado!",
    "falling_death__down_we_go": "¡Allá vamos!",
    "button__play": "¡Jugar!",
    "restart__title": "¿Reiniciar?",
    "restart__are_you_sure": "¿Estás seguro de que quieres reiniciar tu partida?",
    "restart__confirm": "¡Sí!",
    "restart__restarted": "¡Reiniciado!",
    "leaderboard__players": "Jugadores",
    "leaderboard__countries": "Países",
    "leaderboard__falls": "Caídas",
    "leaderboard__hours_played": "Horas Jugadas",
    "leaderboard__got_to_top": "Llegó a la Cima",
    "leaderboard__personal_best": "Mejor Marca Personal",
    "leaderboard__global_rank": "Rango Global",
    "leaderboard__rank": "Rango",
    "leaderboard__runs": "Partidas",
    "leaderboard__time_played": "Tiempo Jugado",
    "button__free_skin": "¡Piel Gratis!"
}
