{
    "error_screen__renderer_failed": "Your graphics card may not support UpTogether! Please try on again on a WebGL2 enabled browser & device.",
    "discord_reward": "Sluit je aan bij onze Discord om een speciale skin te ontgrendelen!",
    "error_screen__server_maybe_full": "Fout bij het verbinden met de server: de servers zijn mogelijk vol op dit moment, probeer het over een paar minuten opnieuw!",
    "error_screen__maintenance_mode": "UpTogether.io wordt bijgewerkt! Kom over een paar minuten terug om de nieuwe dingen te proberen 🎉",
    "error_screen__client_out_of_date": "Uw gameclient is verouderd! Werk uw gameclient bij naar de nieuwste versie en probeer het opnieuw.",
    "error_screen__invalid_connection": "De server heeft uw gebruiker afgewezen! Probeer het opnieuw.",
    "error_screen__already_logged_in": "Je bent al ingelogd!",
    "error_screen__unknown_reason": "Onbekende verbindingsfout :(",
    "error_screen__disconnected_from_server": "Verbinding verbroken met de server! De servers gaan mogelijk offline voor een update. Probeer het over een paar minuten opnieuw.",
    "heightmarker__to_bottom": "Terug naar beneden!",
    "heightmarker__inspiration_come_far": "Kijk hoever je gekomen bent!",
    "heightmarker__inspiration_keep_going": "Blijf doorgaan!",
    "heightmarker__inspiration_up_up": "Omhoog! omhoog! omhoog!",
    "in_game__secret_edward": "Hallo Edward!",
    "heightmarker__secret_gorillas": "Hoeveel gorilla's kun je vinden??",
    "heightmarker__change_character": "Verander karakter",
    "heightmarker__change_name": "Verander naam",
    "heightmarker__change_trail": "Krijg een spoor!",
    "heightmarker__global_leaderboard": "Wereldranglijst",
    "heightmarker__your_stats": "JOUE STATS",
    "heightmarker__game_stats": "SPELSTATS",
    "heightmarker__tutorial_speed": "Snelheid",
    "heightmarker_tutorial_boost": "Boost!",
    "heightmarker__tutorial_climb": "KLIM",
    "heightmarker__tutorial_the_wall": "DE MUUR",
    "heightmarker__tutorial_bouncy": "Stuiterend!",
    "in_game__secret_invis_maze": "Naast de doorgang van het doolhof, aan de linkerkant...",
    "in_game__secret_upside_down_plate": "Spring vooruit vanaf het omgekeerde bord!",
    "in_game__tutorial_start_timer": "Passeer de startlijn om je timer te starten",
    "in_game__tutorial_invis_platforms": "Er zijn onzichtbare platforms!",
    "in_game__how_fast_top": "Hoe snel kun je naar de top komen?",
    "in_game__no_touchy": "NIET AANRAKEN!",
    "in_game__tutorial_running": "Gebruik de linker joystick om te rennen",
    "in_game__tutorial_tap": "Tik",
    "in_game__tutorial_press": "Druk",
    "in_game__tutorial_or": "of",
    "in_game__tutorial__to_jump": "Om te springen",
    "in_game__tutorial__hold": "Vasthouden",
    "in_game__tutorial__jump_repeatedly": "Om herhaaldelijk te springen",
    "in_game__tutorial_wall_climb": "Om muren te beklimmen",
    "in_game__tutorial_while_running": "Terwijl je rent",
    "in_game__tutorial_drag_move_camera": "Sleep het scherm om de camera te verplaatsen",
    "in_game__checkpoint": "Controlepunt!",
    "loading_text__connecting": "Verbinden met de server...",
    "loading_text__splines": "Splines reticuleren",
    "loading_text__tofu": "Tofu marineren",
    "loading_text__corn": "De maïs pellen",
    "loading_text__broccoli": "Bevestigen dat broccoli kleine bomen zijn",
    "loading_text__brussel_sprouts": "Spelers overtuigen dat spruitjes heerlijk zijn",
    "loading_text__secret_sauce": "Het geheime saus toevoegen",
    "loading_text__digesting": "Verteren",
    "loading_text__stirring": "Constant roeren",
    "loading_text__fixing_ice_cream": "De ijsmachine bij McDonald's repareren",
    "loading_text__chopping_broccoli": "De broccoli hakken",
    "loading_text__carmelize_apples": "De appels karameliseren",
    "loading_text__water_boil": "Kijken hoe het water kookt",
    "loading_text__filleting_fish": "De vis fileren",
    "loading_text__plating": "Het opmaken",
    "loading_text__garnishing": "Garneren",
    "loading_text__moldy_bits": "Het beschimmelde deel afsnijden",
    "death_block__almost": "Bijna!",
    "death_block__close_one": "Net gemist!",
    "death_block__try_again": "Probeer opnieuw!",
    "death_block__roasted": "Geroosterd!",
    "death_block__burnt": "Verbrand!",
    "death_block__oh_no": "Oh nee!",
    "death_block__grilled": "Gegrild!",
    "death_block__blanched": "Gebalanceerd!",
    "death_block__crisp": "Krokant!",
    "death_block__flameed": "Flambéed!",
    "death_block__order_up": "Bestelling klaar!",
    "death_block__ouch": "Au!",
    "death_block__good_try": "Goed geprobeerd!",
    "death_block__baked": "Gebakken!",
    "death_block__crusty": "Krokant!",
    "death_block__next_time": "Volgende keer!",
    "death_block__toast": "Je bent toast!",
    "death_block__no_chef": "Geen chef-kok!",
    "death_block__singe_left": "Net een beetje geschroeid aan de linkerkant!",
    "death_block__oof": "Oof!",
    "death_block__fried": "Gestruikeld en gebakken!",
    "death_block__recipe_ruined": "Recept verpest",
    "death_block__sizzling_stop": "Een sissend einde!",
    "death_block__defeated": "Chef verslagen!",
    "death_block__burnt_out": "Opgebrand?",
    "death_block__meltdown": "Een meltdown?",
    "falling_death__slippery": "Glad!",
    "falling_death__gg": "GG",
    "falling_death__you_died": "Je bent dood ",
    "falling_death__f": "F",
    "falling_death__floor_food": "Eten op de vloer!",
    "falling_death__three_second": "Drie seconden regel!",
    "falling_death__floor_seasoning": "Mmm! Kruiden van de vloer!",
    "falling_death__just_missed": "Net gemist!",
    "falling_death__rip": "Rust in vrede",
    "falling_death__floor_vs_flavor": "Vloer: 1",
    "falling_death__floor_fall": "Vloerfout!",
    "falling_death__saucy_slip": "Smaakvolle uitglijder!",
    "falling_death__fried_fell": "Gefrituurd en gevallen!",
    "falling_death__dive": "Smaakloze duik!",
    "falling_death__floor_finish": "Afwerking op de vloer!",
    "falling_death__dinner_dive": "Dinerduik!",
    "falling_death__splat": "SPLAT!",
    "falling_death__oops": "Oeps",
    "falling_death__greasy_spot": "Je hebt de vette plek gevonden!",
    "falling_death__barely_missed": "Net gemist!",
    "falling_death__down_we_go": "Daar gaan we!",
    "button__play": "Spelen!",
    "restart__title": "Opnieuw beginnen?",
    "restart__are_you_sure": "Weet je zeker dat je je run opnieuw wilt starten?",
    "restart__confirm": "Ja!",
    "restart__restarted": "Opnieuw gestart...",
    "leaderboard__players": "Spelers",
    "leaderboard__countries": "Landen",
    "leaderboard__falls": "Vallen",
    "leaderboard__hours_played": "Uren Gespeeld",
    "leaderboard__got_to_top": "Bereikte de Top",
    "leaderboard__personal_best": "Persoonlijk Record",
    "leaderboard__global_rank": "Wereldwijde Rang",
    "leaderboard__rank": "Rang",
    "leaderboard__runs": "Spelrondes",
    "leaderboard__time_played": "Speeltijd",
    "button__free_skin": "Gratis Huid!"
}
