{
    "error_screen__renderer_failed": "Your graphics card may not support UpTogether! Please try on again on a WebGL2 enabled browser & device.",
    "discord_reward": "Rejoignez notre Discord pour débloquer un skin spécial !",
    "error_screen__server_maybe_full": "Erreur de connexion au serveur : les serveurs sont peut-être pleins en ce moment, réessayez dans quelques minutes !",
    "error_screen__maintenance_mode": "UpTogether.io est en cours de mise à jour ! Revenez dans quelques minutes pour essayer les nouveautés 🎉",
    "error_screen__client_out_of_date": "Votre client de jeu n'est pas à jour ! Veuillez mettre à jour votre client de jeu vers la dernière version et réessayez.",
    "error_screen__invalid_connection": "Le serveur a rejeté votre utilisateur ! Veuillez réessayer.",
    "error_screen__already_logged_in": "Vous êtes déjà connecté !",
    "error_screen__unknown_reason": "Erreur de connexion inconnue :(",
    "error_screen__disconnected_from_server": "Déconnecté du serveur ! Les serveurs peuvent être en cours de mise à jour. Réessayez dans quelques minutes.",
    "heightmarker__to_bottom": "Retour en bas !",
    "heightmarker__inspiration_come_far": "Regardez le chemin parcouru !",
    "heightmarker__inspiration_keep_going": "Continuez !",
    "heightmarker__inspiration_up_up": "Haut ! haut ! haut !",
    "in_game__secret_edward": "Salut Edward !!",
    "heightmarker__secret_gorillas": "Combien de gorilles pouvez-vous trouver ??",
    "heightmarker__change_character": "Changer de personnage",
    "heightmarker__change_name": "Changer de nom",
    "heightmarker__change_trail": "Obtenez une trace !",
    "heightmarker__global_leaderboard": "Classement mondial",
    "heightmarker__your_stats": "VOS STATS",
    "heightmarker__game_stats": "STATISTIQUES DU JEU",
    "heightmarker__tutorial_speed": "Vitesse",
    "heightmarker_tutorial_boost": "Boost !",
    "heightmarker__tutorial_climb": "GRIMPER",
    "heightmarker__tutorial_the_wall": "LE MUR",
    "heightmarker__tutorial_bouncy": "Bondissant !",
    "in_game__secret_invis_maze": "À côté de la porte du labyrinthe, à gauche...",
    "in_game__secret_upside_down_plate": "Sautez en avant depuis l'assiette à l'envers !",
    "in_game__tutorial_start_timer": "Passez la ligne de départ pour démarrer votre chronomètre",
    "in_game__tutorial_invis_platforms": "Il y a des plates-formes invisibles !",
    "in_game__how_fast_top": "À quelle vitesse pouvez-vous atteindre le sommet ?",
    "in_game__no_touchy": "NE PAS TOUCHER !",
    "in_game__tutorial_running": "Utilisez le joystick gauche pour courir",
    "in_game__tutorial_tap": "Taper",
    "in_game__tutorial_press": "Appuyer",
    "in_game__tutorial_or": "ou",
    "in_game__tutorial__to_jump": "Pour sauter",
    "in_game__tutorial__hold": "Tenir",
    "in_game__tutorial__jump_repeatedly": "Pour sauter à plusieurs reprises",
    "in_game__tutorial_wall_climb": "Pour grimper aux murs",
    "in_game__tutorial_while_running": "En courant",
    "in_game__tutorial_drag_move_camera": "Faites glisser l'écran pour déplacer la caméra",
    "in_game__checkpoint": " Point de contrôle!",
    "loading_text__connecting": "Connexion au serveur...",
    "loading_text__splines": "Rectification des splines",
    "loading_text__tofu": "Mariner le tofu",
    "loading_text__corn": "Éplucher le maïs",
    "loading_text__broccoli": "Confirmation que le brocoli est fait de petits arbres",
    "loading_text__brussel_sprouts": "Convaincre les joueurs que les choux de Bruxelles sont délicieux",
    "loading_text__secret_sauce": "Ajout de la sauce secrète",
    "loading_text__digesting": "Digestion",
    "loading_text__stirring": "Remuer constamment",
    "loading_text__fixing_ice_cream": "Réparation de la machine à glace chez McDonald's",
    "loading_text__chopping_broccoli": "Couper le brocoli",
    "loading_text__carmelize_apples": "Caramélisation des pommes",
    "loading_text__water_boil": "Regarder l'eau bouillir",
    "loading_text__filleting_fish": "Fileter le poisson",
    "loading_text__plating": "Plaquer",
    "loading_text__garnishing": "Garnir",
    "loading_text__moldy_bits": "Couper les parties moisies",
    "death_block__almost": "Presque!",
    "death_block__close_one": "Tout près!",
    "death_block__try_again": "Essaie encore!",
    "death_block__roasted": "Rôti !",
    "death_block__burnt": "Brûlé !",
    "death_block__oh_no": "Oh non!",
    "death_block__grilled": "Grillé !",
    "death_block__blanched": "Blanchi !",
    "death_block__crisp": "Croustillant !",
    "death_block__flameed": "Flambé !",
    "death_block__order_up": "Commande prête !",
    "death_block__ouch": "Aïe !",
    "death_block__good_try": "Bien essayé!",
    "death_block__baked": "Cuit au four !",
    "death_block__crusty": "Croustillant !",
    "death_block__next_time": "La prochaine fois!",
    "death_block__toast": "Tu es grillé !",
    "death_block__no_chef": "Pas de chef !",
    "death_block__singe_left": "Juste une légère brûlure à gauche !",
    "death_block__oof": "Oof !",
    "death_block__fried": "Maladroit et frit !",
    "death_block__recipe_ruined": "Recette ruinée",
    "death_block__sizzling_stop": "Un arrêt qui crépite !",
    "death_block__defeated": "Chef vaincu !",
    "death_block__burnt_out": "Tu es épuisé ?",
    "death_block__meltdown": "En pleine crise ?",
    "falling_death__slippery": "Glissant!",
    "falling_death__gg": "GG",
    "falling_death__you_died": "Tu es mort ",
    "falling_death__f": "F",
    "falling_death__floor_food": "Nourriture au sol !",
    "falling_death__three_second": "Règle des trois secondes !",
    "falling_death__floor_seasoning": "Mmm ! Assaisonnement au sol !",
    "falling_death__just_missed": "Tout juste raté!",
    "falling_death__rip": "Repose en paix",
    "falling_death__floor_vs_flavor": "Sol : 1",
    "falling_death__floor_fall": "Échec au sol !",
    "falling_death__saucy_slip": "Glissade saucée !",
    "falling_death__fried_fell": "Frit et tombé !",
    "falling_death__dive": "Plongée sans saveur !",
    "falling_death__floor_finish": "Finition au sol !",
    "falling_death__dinner_dive": "Plongée dînatoire !",
    "falling_death__splat": "SPLAT !",
    "falling_death__oops": "Oops",
    "falling_death__greasy_spot": "Vous avez trouvé l'endroit gras !",
    "falling_death__barely_missed": "Tout juste raté!",
    "falling_death__down_we_go": "En descente!",
    "button__play": "Jouer !",
    "restart__title": "Recommencer ?",
    "restart__are_you_sure": "Êtes-vous sûr de vouloir recommencer votre partie ?",
    "restart__restarted": "Recommencé...",
    "leaderboard__players": "Joueurs",
    "leaderboard__countries": "Pays",
    "leaderboard__falls": "Chutes",
    "leaderboard__hours_played": "Heures Jouées",
    "leaderboard__got_to_top": "Atteint le Sommet",
    "leaderboard__personal_best": "Meilleur Personnel",
    "leaderboard__global_rank": "Classement Mondial",
    "leaderboard__rank": "Classement",
    "leaderboard__runs": "Parties",
    "leaderboard__time_played": "Temps Joué",
    "button__free_skin": "Peau Gratuite!"
}
