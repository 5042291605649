/********************************************/
/*                                          */
/*      Character Models & Animations       */
/*                                          */
/********************************************/

export enum Characters {
    Broccoli = 0,
    Blueberry = 1,
    Apple = 2,
    Cupcake = 3,
    Icecream = 4,
    Hotdog = 5,
    Peach = 6,
    Eggplant = 7,
    Banana = 8,
    Baguette = 9,
    Taco = 10,
    Turkeyleg = 11,
    Candycane = 12,
    Bacon = 13
}

export const ChristmasCharacters = [Characters.Turkeyleg, Characters.Candycane];

export enum CharacterAnimations {
    Idle = "idle",
    Walking = "walking",
    Jumping = "jumping_up",
    Death = "death"
}

export interface CharacterDetails {
    identifier: Characters;
    assetPath: string;
    requiresAdToUnlock: boolean;
}

export const CharacterSkinMetadata: Map<Characters, CharacterDetails> = new Map();

CharacterSkinMetadata.set(Characters.Broccoli, {
    identifier: Characters.Broccoli,
    assetPath: `characters/${process.env.ASSET_THEME}/broccoli.glb`,
    requiresAdToUnlock: false
});

CharacterSkinMetadata.set(Characters.Blueberry, {
    identifier: Characters.Blueberry,
    assetPath: `characters/${process.env.ASSET_THEME}/blueberry.glb`,
    requiresAdToUnlock: false
});

CharacterSkinMetadata.set(Characters.Apple, {
    identifier: Characters.Apple,
    assetPath: `characters/${process.env.ASSET_THEME}/apple.glb`,
    requiresAdToUnlock: false
});

CharacterSkinMetadata.set(Characters.Cupcake, {
    identifier: Characters.Cupcake,
    assetPath: `characters/${process.env.ASSET_THEME}/cupcake.glb`,
    requiresAdToUnlock: true
});

CharacterSkinMetadata.set(Characters.Icecream, {
    identifier: Characters.Icecream,
    assetPath: `characters/${process.env.ASSET_THEME}/icecream.glb`,
    requiresAdToUnlock: true
});

CharacterSkinMetadata.set(Characters.Hotdog, {
    identifier: Characters.Hotdog,
    assetPath: `characters/${process.env.ASSET_THEME}/hotdog.glb`,
    requiresAdToUnlock: true
});

CharacterSkinMetadata.set(Characters.Peach, {
    identifier: Characters.Peach,
    assetPath: `characters/${process.env.ASSET_THEME}/peach.glb`,
    requiresAdToUnlock: true
});

CharacterSkinMetadata.set(Characters.Eggplant, {
    identifier: Characters.Eggplant,
    assetPath: `characters/${process.env.ASSET_THEME}/eggplant.glb`,
    requiresAdToUnlock: true
});

CharacterSkinMetadata.set(Characters.Banana, {
    identifier: Characters.Banana,
    assetPath: `characters/${process.env.ASSET_THEME}/banana.glb`,
    requiresAdToUnlock: true
});
CharacterSkinMetadata.set(Characters.Baguette, {
    identifier: Characters.Baguette,
    assetPath: `characters/${process.env.ASSET_THEME}/baguette.glb`,
    requiresAdToUnlock: true
});

CharacterSkinMetadata.set(Characters.Taco, {
    identifier: Characters.Taco,
    assetPath: `characters/${process.env.ASSET_THEME}/taco.glb`,
    requiresAdToUnlock: true
});

CharacterSkinMetadata.set(Characters.Turkeyleg, {
    identifier: Characters.Turkeyleg,
    assetPath: "characters/CHRISTMAS/turkeyleg.glb",
    requiresAdToUnlock: true
});

CharacterSkinMetadata.set(Characters.Candycane, {
    identifier: Characters.Candycane,
    assetPath: "characters/CHRISTMAS/candycane.glb",
    requiresAdToUnlock: true
});

CharacterSkinMetadata.set(Characters.Bacon, {
    identifier: Characters.Bacon,
    assetPath: `characters/${process.env.ASSET_THEME}/bacon.glb`,
    requiresAdToUnlock: true
});
