{
    "error_screen__renderer_failed": "Your graphics card may not support UpTogether! Please try on again on a WebGL2 enabled browser & device.",
    "discord_reward": "加入我们的 Discord 解锁特殊皮肤！",
    "error_screen__server_maybe_full": "连接服务器时出错：服务器可能已满，请在几分钟后重试！",
    "error_screen__maintenance_mode": "UpTogether.io 正在更新中！几分钟后回来尝试新的东西 🎉",
    "error_screen__client_out_of_date": "您的游戏客户端已过时！请更新到最新版本然后重试。",
    "error_screen__invalid_connection": "服务器拒绝了您的用户！请重试。",
    "error_screen__already_logged_in": "您已经登录！",
    "error_screen__unknown_reason": "未知连接错误 :(",
    "error_screen__disconnected_from_server": "与服务器断开连接！服务器可能正在进行更新。请在几分钟后重试。",
    "heightmarker__to_bottom": "回到底部！",
    "heightmarker__inspiration_come_far": "看看你走了多远！",
    "heightmarker__inspiration_keep_going": "继续前进！",
    "heightmarker__inspiration_up_up": "上！上！上！",
    "in_game__secret_edward": "嗨，爱德华！！",
    "heightmarker__secret_gorillas": "你能找到多少猩猩？？",
    "heightmarker__change_character": "更改角色",
    "heightmarker__change_name": "更改名称",
    "heightmarker__change_trail": "获取拖尾！",
    "heightmarker__global_leaderboard": "全球排行榜",
    "heightmarker__your_stats": "您的统计",
    "heightmarker__game_stats": "游戏统计",
    "heightmarker__tutorial_speed": "速度",
    "heightmarker_tutorial_boost": "加速！",
    "heightmarker__tutorial_climb": "攀爬",
    "heightmarker__tutorial_the_wall": "墙",
    "heightmarker__tutorial_bouncy": "有弹性的！",
    "in_game__secret_invis_maze": "在迷宫门旁边，靠左...",
    "in_game__secret_upside_down_plate": "从倒置的盘子上跳出去！",
    "in_game__tutorial_start_timer": "越过起跑线开始计时器",
    "in_game__tutorial_invis_platforms": "有隐形平台！",
    "in_game__how_fast_top": "你能多快到达顶部？",
    "in_game__no_touchy": "不要触碰！",
    "in_game__tutorial_running": "使用左摇杆奔跑",
    "in_game__tutorial_tap": "轻触",
    "in_game__tutorial_press": "按",
    "in_game__tutorial_or": "或",
    "in_game__tutorial__to_jump": "跳跃",
    "in_game__tutorial__hold": "保持",
    "in_game__tutorial__jump_repeatedly": "重复跳跃",
    "in_game__tutorial_wall_climb": "爬墙",
    "in_game__tutorial_while_running": "在奔跑时",
    "in_game__tutorial_drag_move_camera": "拖动屏幕以移动摄像头",
    "in_game__checkpoint": "检查站",
    "loading_text__connecting": " 连接到服务器中...",
    "loading_text__splines": "曲线插值中",
    "loading_text__tofu": "滷豆腐中",
    "loading_text__corn": "摘玉米中",
    "loading_text__broccoli": "确认西兰花是小树",
    "loading_text__brussel_sprouts": "说服玩家布鲁塞尔芽是美味的",
    "loading_text__secret_sauce": "加入秘制酱料",
    "loading_text__digesting": "消化中",
    "loading_text__stirring": "持续搅拌",
    "loading_text__fixing_ice_cream": "修理麦当劳的冰淇淋机",
    "loading_text__chopping_broccoli": "切西兰花",
    "loading_text__carmelize_apples": "焦糖苹果",
    "loading_text__water_boil": "看水烧开",
    "loading_text__filleting_fish": "去鱼骨",
    "loading_text__plating": "上菜",
    "loading_text__garnishing": "装饰",
    "loading_text__moldy_bits": "切掉发霉的部分",
    "death_block__almost": "差点!",
    "death_block__close_one": "差一点!",
    "death_block__try_again": "再试一次!",
    "death_block__roasted": "烤熟了！",
    "death_block__burnt": "烧焦了！",
    "death_block__oh_no": "哦不!",
    "death_block__grilled": "烤熟了！",
    "death_block__blanched": "漂白了！",
    "death_block__crisp": "脆皮！",
    "death_block__flameed": "火焰烹饪！",
    "death_block__order_up": "上菜！",
    "death_block__ouch": "哎哟！",
    "death_block__good_try": "不错哦!",
    "death_block__baked": "烤了！",
    "death_block__crusty": "硬皮！",
    "death_block__next_time": "下次再试！",
    "death_block__toast": "你烤糊了！",
    "death_block__no_chef": "没有厨师！",
    "death_block__singe_left": "只是稍微烧焦了左边！",
    "death_block__oof": "哎呀！",
    "death_block__fried": "笨拙而油炸！",
    "death_block__recipe_ruined": "食谱毁了",
    "death_block__sizzling_stop": "滋滋作响的停止！",
    "death_block__defeated": "厨师被打败了！",
    "death_block__burnt_out": "烧毁了？",
    "death_block__meltdown": "崩溃了？",
    "falling_death__slippery": "滑!",
    "falling_death__gg": "干得好",
    "falling_death__you_died": "你死了 ",
    "falling_death__f": "F",
    "falling_death__floor_food": "地板食物！",
    "falling_death__three_second": "三秒原则！",
    "falling_death__floor_seasoning": "嗯！地板调味料！",
    "falling_death__just_missed": "差一点!",
    "falling_death__rip": "安息",
    "falling_death__floor_vs_flavor": "地板：1",
    "falling_death__floor_fall": "地板失败！",
    "falling_death__saucy_slip": "多汁的滑倒！",
    "falling_death__fried_fell": "油炸而倒下！",
    "falling_death__dive": "无味的潜水！",
    "falling_death__floor_finish": "地板完成！",
    "falling_death__dinner_dive": "晚餐潜水！",
    "falling_death__splat": "啪！",
    "falling_death__oops": "哎呀",
    "falling_death__greasy_spot": "你找到了油腻的地方！",
    "falling_death__barely_missed": "勉强错过!",
    "falling_death__down_we_go": "下降!",
    "button__play": "播放！",
    "restart__title": "重新开始？",
    "restart__are_you_sure": "您确定要重新开始吗？",
    "restart__confirm": "是的！",
    "restart__restarted": "已重新开始...",
    "leaderboard__players": "玩家",
    "leaderboard__countries": "国家",
    "leaderboard__falls": "跌落",
    "leaderboard__hours_played": "游戏时长",
    "leaderboard__got_to_top": "登顶",
    "leaderboard__personal_best": "个人最佳",
    "leaderboard__global_rank": "全球排名",
    "leaderboard__rank": "排名",
    "leaderboard__runs": "游戏轮数",
    "leaderboard__time_played": "游戏时间",
    "button__free_skin": "免费皮肤！"
}
